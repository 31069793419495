<div class="h-100 d-flex align-items-center">
    <div class="container d-flex flex-column">
        <h2 class="text-center">Agregar cliente</h2>
        <div class="row mb-3">
            <div class="col-md-6">
                <input [formControl]="nombre" type="text" class="form-control" placeholder="Ingrese Razon social">
            </div>
            <div class="col-md-6">
                <input [formControl]="ruc" type="text" class="form-control" placeholder="Ingrese RUC">
            </div>
            <div class="col-md-5">
                <input [formControl]="correo" type="text" class="form-control" placeholder="Ingrese correo">
            </div>
            <div class="col-md-5">
                <input [formControl]="phone" type="text" class="form-control" placeholder="Ingrese numero celular">
            </div>
            <div class="col-md-2">
                <button [disabled]="nombre.value==='' || ruc.value==='' || correo.value==='' || phone.value===''" class="btn btn-secondary btn-block" (click)="addCredencial()">Generar credencial</button>
            </div>
        </div>
        <div class="dropdown-divider mb-4"></div>
        <h2 class="text-center">Lista de clientes</h2>
        <div class="mb-3">
            <label for="buscador" class="form-label">Buscador de cliente:</label>
            <div class="input-group">
                <input [formControl]="buscador" type="text" class="form-control" id="buscador" placeholder="Ingrese correo" aria-label="Recipient's username with two button addons">
                <button [hidden]="buscador.value === ''" (click)="searchCorreo('clean')" class="btn btn-outline-secondary" type="button">x</button>
                <button [hidden]="buscador.value === ''" (click)="searchCorreo('buscar')" (keyup.enter)="searchCorreo('buscar')" class="btn btn-outline-secondary" type="button">Buscar</button>
            </div>
        </div>
        <div class="row bg-secondary py-1">
            <div class="col-md-3 fw-bold">
                Información:
            </div>
            <div class="col-md-3 fw-bold">
                Telefono:
            </div>
            <div class="col-md-2 fw-bold">
                Clave:
            </div>
            <div class="col-md-2 fw-bold">
                Estado:
            </div>
            <div class="col-md-2 fw-bold">
                Acción:
            </div>
        </div>
        <app-item3 class="row mb-3 align-items-center" *ngFor="let credencial of credenciales" [credencial]="credencial"></app-item3>
    </div>
</div>
