import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdministracionComponent } from './page/administracion/administracion.component';
import { AutCredencialesComponent } from './page/administracion/aut-credenciales/aut-credenciales.component';
import { ListPedidosComponent } from './page/administracion/list-pedidos/list-pedidos.component';
import { ListProductosComponent } from './page/administracion/list-productos/list-productos.component';
import { PedidosComponent } from './page/pedidos/pedidos.component';
import { SesionComponent } from './page/sesion/sesion.component';
import { MyGuardGuard } from './services/my-guard.guard';

const routes: Routes = [
  { path: '', component: SesionComponent },
  { path: 'pedidos/:email', component: PedidosComponent, canActivate: [MyGuardGuard] },
  { path: 'administracion',
    canActivate: [MyGuardGuard],
    children: [
      {path: '', component: AdministracionComponent},
      {path: 'credenciales', component: AutCredencialesComponent},
      {path: 'productos', component: ListProductosComponent},
      {path: 'pedidos', component: ListPedidosComponent},
    ]
  },
  { path: '**', component: SesionComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
