import { Component, Input, OnInit } from '@angular/core';
import { Producto } from 'src/app/model/producto';

@Component({
  selector: 'app-target2',
  templateUrl: './target2.component.html',
  styleUrls: ['./target2.component.css']
})
export class Target2Component implements OnInit {

  @Input() itemsPedido:Array<{producto:Producto, cantidad:string, subtotal:string}>;

  constructor() { }

  ngOnInit(): void {
  }

}
