import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ComBackendService } from 'src/app/services/com-backend.service';
import { FuncionesService } from 'src/app/services/funciones.service';
import { IntercomService } from 'src/app/services/intercom.service';

@Component({
  selector: 'app-sesion',
  templateUrl: './sesion.component.html',
  styleUrls: ['./sesion.component.css']
})
export class SesionComponent implements OnInit {

  formData:FormData;

  constructor(private router: Router, private interCom: IntercomService, private comBackend:ComBackendService,
    private funciones:FuncionesService) { }

  ngOnInit(): void {
  }

  async validar(email:HTMLInputElement, password:HTMLInputElement){

    this.interCom.showModal(true, {message:'autenticando...', title:'Aviso'});
    this.formData = this.funciones.getFormDataUp({
      url:'credenciales',
      params: 'AUTH0',
      data: JSON.stringify({ table:'usuarios', condition: `correo='${email.value}' AND clave='${password.value}'` })
    });
    let response = await this.comBackend.getRequestBackend(this.formData);
    console.log(response); //Log de Ayuda
    setTimeout(() => {
      this.interCom.showModal(true);
    }, 1500);
    
    setTimeout(() => {
      if (email.value === 'nglogisticeirl@gmail.com' && Number.parseInt(response.response) > 0) {
        localStorage.setItem("permission", "true");
        this.router.navigate(['/administracion']);
      } else if (Number.parseInt(response.response) > 0) {
        localStorage.setItem("permission", "true");
        this.router.navigate(['pedidos', email.value]);
      } else if (Number.parseInt(response.response) === 0){
        this.interCom.showModal(false,{message:'Usuario o contraseña incorrectas', title:'Error de autenticación'});
      }
    }, 2000);
  }

}
