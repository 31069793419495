import { Component, OnDestroy, OnInit, ɵangular_packages_core_core_o } from '@angular/core';
import { FormControl } from '@angular/forms';
import { getMaxListeners } from 'process';
import { ComBackendService } from 'src/app/services/com-backend.service';
import { FuncionesService } from 'src/app/services/funciones.service';
import { IntercomService } from 'src/app/services/intercom.service';

@Component({
  selector: 'app-aut-credenciales',
  templateUrl: './aut-credenciales.component.html',
  styleUrls: ['./aut-credenciales.component.css']
})
export class AutCredencialesComponent implements OnInit,OnDestroy {

  formData:FormData;
  buscador:FormControl = new FormControl('');
  nombre:FormControl = new FormControl('');
  ruc:FormControl = new FormControl('');
  correo:FormControl = new FormControl('');
  phone:FormControl = new FormControl('');
  credenciales:Array<{correo:string, usuario:string, clave:string, estado:string}> = [];

  constructor(private interCom:IntercomService, private comBackend:ComBackendService, private funciones:FuncionesService) {
    interCom.correo$.subscribe(correo => {
      let posicion = this.credenciales.map(function(e) { return e.correo; }).indexOf(correo);
      this.credenciales.splice(posicion,1);
    });
  }

  ngOnInit(): void {
    this.initializer();
  }
  ngOnDestroy(): void {
    localStorage.removeItem('aut-credenciales');
  }

  async initializer(){
    this.credenciales = [];
    this.interCom.showModal(true,{message:'cargando...', title:'Aviso'});
    this.formData = this.funciones.getFormDataUp({
      url:'credenciales',
      params: 'GET',
      data: JSON.stringify({ clausula_where: 'none' })
    });
    let response = await this.comBackend.getRequestBackend(this.formData);
    console.log(response); //Log de Ayuda
    this.credenciales = response.response;
    localStorage.setItem('aut-credenciales', JSON.stringify(this.credenciales));
    setTimeout(() => {
      this.interCom.showModal(true);
    }, 2000);
  }

  searchCorreo(criterio:string){

    if (criterio === "clean" && localStorage.getItem('aut-credenciales').length > 0){
      this.buscador.reset('');
      this.credenciales = JSON.parse(localStorage.getItem('aut-credenciales'));
    } else if (criterio === "buscar" && localStorage.getItem('aut-credenciales').length > 0) {
      this.credenciales = [];
      JSON.parse(localStorage.getItem('aut-credenciales')).forEach(credencial =>{
        if (credencial.correo.toLocaleLowerCase().includes(this.buscador.value.toLocaleLowerCase())) {
          this.credenciales.push(credencial);
        }
      })
    }
  }

  async addCredencial(){

    this.interCom.showModal(true,{message:'cargando...', title:'Aviso'});
    this.formData = this.funciones.getFormDataUp({
      url:'credenciales',
      params: 'POST',
      data: JSON.stringify({ nombre: this.nombre.value, ruc: this.ruc.value, correo: this.correo.value, telefono: this.phone.value })
    });
    let response = await this.comBackend.getRequestBackend(this.formData);
    console.log(response); //Log de Ayuda
    response.response ? this.resetInsert() : window.alert('Correo ya existe o hubo un error de conexion.');
    this.interCom.showModal(true);
    setTimeout(() => {
      this.initializer();
    }, 1000);
  }

  resetInsert(){
    this.correo.reset('');
    this.phone.reset('');
  }

}
