import { Component, Input, OnInit } from '@angular/core';
import { IntercomService } from 'src/app/services/intercom.service';

@Component({
  selector: 'app-item5',
  templateUrl: './item5.component.html',
  styleUrls: ['./item5.component.css']
})
export class Item5Component implements OnInit {

  @Input() pedido:{
    estado:string,
    lista:{
      producto:{
        codigo:string,
        laboratorio:string,
        precio:string,
        producto:string,
        visible:string,
        descuento:string},
      cantidad:number,
      subtotal:string},
    monto:string,
    fecha:string,
    correo:string,
    numpedido:string}

  constructor(private interCom:IntercomService) { }

  ngOnInit(): void {
  }

  itemSelect(){
    
    this.interCom.announcePedido(this.pedido);
  }

}
