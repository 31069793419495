import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Producto } from 'src/app/model/producto';

@Component({
  selector: 'app-target1',
  templateUrl: './target1.component.html',
  styleUrls: ['./target1.component.css']
})
export class Target1Component implements OnInit {

  @Input() productos:Array<Producto>;

  constructor() { }

  ngOnInit(): void {
  }

}
