<div class="h-100 d-flex align-items-center">
    <div class="container d-flex flex-column">
        <div class="row">
            <div class="col">
                <button class="btn h-100 w-100" routerLink="/administracion/credenciales">
                    <div class="card">
                        <span class="text-center"><svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" fill="none" stroke="#060606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg></span>
                        <div class="card-body">
                            <h5 class="card-title text-center">Autorización de credenciales</h5>
                        </div>
                    </div>
                </button>
            </div>
            <div class="col">
                <button class="btn h-100 w-100" routerLink="/administracion/productos">
                    <div class="card" >
                        <span class="text-center"><svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" fill="none" stroke="#060606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg></span>
                        <div class="card-body">
                            <h5 class="card-title text-center">Lista de productos</h5>
                        </div>
                    </div>
                </button>
            </div>
            <div class="col">
                <button class="btn h-100 w-100" routerLink="/administracion/pedidos">
                    <div class="card">
                        <span class="text-center"><svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" fill="none" stroke="#060606" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21.5 12H16c-.7 2-2 3-4 3s-3.3-1-4-3H2.5"/><path d="M5.5 5.1L2 12v6c0 1.1.9 2 2 2h16a2 2 0 002-2v-6l-3.4-6.9A2 2 0 0016.8 4H7.2a2 2 0 00-1.8 1.1z"/></svg></span>
                        <div class="card-body">
                            <h5 class="card-title text-center">Bandeja de pedidos</h5>
                        </div>
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>

<a (click)="signOut()" class="btn-flotante">
    Cerrar sesión
  </a>
  
  <style>
     .btn-flotante {
      cursor: pointer;
      text-decoration: none;
      font-size: 14px; /* Cambiar el tamaño de la tipografia */
      text-transform: uppercase; /* Texto en mayusculas */
      font-weight: bold; /* Fuente en negrita o bold */
      color: #ffffff; /* Color del texto */
      border-radius: 5px; /* Borde del boton */
      letter-spacing: 2px; /* Espacio entre letras */
      background-color: #E91E63; /* Color de fondo */
      padding: 5px; /* Relleno del boton */
      position: fixed;
      top: 40px;
      right: 40px;
      transition: all 300ms ease 0ms;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
      z-index: 9999;
    }
  </style>