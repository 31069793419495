<div [formGroup]="productoForm" class="row m-0 formProducto">
    <div class="row mb-3">
        <div class="col-md-3">
            <label class="form-label">Codigo:</label>
            <input
                readonly
                type="text"
                formControlName="codigo"
                class="form-control"
                placeholder="codigo">
        </div>
        <div class="col-md-5">
            <label class="form-label">Producto:</label>
            <input
                type="text"
                formControlName="producto"
                class="form-control"
                placeholder="producto">
        </div>
        <div class="col-md-4">
            <label class="form-label">Laboratorio:</label>
            <input
                type="text"
                formControlName="laboratorio"
                class="form-control"
                placeholder="laboratorio">
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-4 align-self-center">
            <div class="form-check ms-5">
                <label class="form-check-label">Visible:</label>
                <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="visible"
                    checked>
            </div>
        </div>
        <div class="col-md-4">
            <label class="form-label">Precio de venta:</label>
            <input
                type="number"
                formControlName="precio"
                class="form-control"
                placeholder="00.00">
        </div>
        <div class="col-md-4">
            <label class="form-label">Descuento por docena (%):</label>
            <input
                type="number"
                formControlName="descuento"
                class="form-control"
                placeholder="00">
        </div>
    </div>
    <div class="row justify-content-evenly mb-3">
        <div class="col-md-5 text-center">
            <button class="btn btn-secondary w-25" (click)="saveChange('update')">Guardar</button>
            <button class="btn btn-secondary w-25 ms-2" (click)="saveChange('delete')">Borrar</button>
        </div>
    </div>
</div>
