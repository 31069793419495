import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


interface IPedidos{
  estado:string,
  lista:[],
  monto:string,
  fecha:string,
  correo:string,
  numpedido:string,
  nombre:string,
  ruc:string,
  telefono:string,
  observacion:string
}


@Injectable({
  providedIn: 'root'
})
export class IntercomService {

  constructor() { }

  // Observable string sources
  private itemPedidoSource = new Subject<any>();
  private codigoSource = new Subject<string>();
  private correoSource = new Subject<string>();
  private pedidoSource = new Subject<IPedidos>();

  // Observable string streams
  itemPedido$ = this.itemPedidoSource.asObservable();
  codigo$ = this.codigoSource.asObservable();
  correo$ = this.correoSource.asObservable();
  pedido$ = this.pedidoSource.asObservable();

  // Service message commands
  announceitemPedido(itemPedido:any) {
    this.itemPedidoSource.next(itemPedido);
  }

  announceCodigo(codigo:string) {
    this.codigoSource.next(codigo);
  }

  announceCorreo(correo:string) {
    this.correoSource.next(correo);
  }

  announcePedido(pedido:any) {
    this.pedidoSource.next(pedido);
  }

  showModal(modalStatic:boolean, element?:{message:string, title:string}){
        
    let modal = document.getElementById("modalGlobal");
    let divModal = document.getElementById("staticBackdrop");
    let btnClose = document.getElementById("btnClose");
    let modalTitle = document.getElementById("titleModal");
    let modalBody = document.getElementById("bodyModal");

    if (typeof element !== 'undefined'){
      modalTitle.innerHTML = element.title;
      modalBody.innerHTML = element.message;
    } else if (typeof element === 'undefined'){
      divModal.getAttribute("class").includes("show") ? modal.click() : '';
    }

    btnClose.hidden = modalStatic;
    divModal.getAttribute("class").includes("show") ? '' : modal.click();
  }

}
