<div class="col d-flex flex-column h-sm-100 overflow-auto">
  <header class="bg-info py-1">
    <div class="nav justify-content-center bg-info">
      <img src="https://nyglogistic.com/assets/img_2.png" class="img-fluid" alt="Logo">
    </div>
  </header>

  <main id="main" class="row mt-auto m-0">
    <router-outlet class="d-none"></router-outlet>
  </main>

  <footer class="row bg-info py-1 mt-auto m-0">
    <div class="col text-white text-center" style="font-size: 0.625em;"><span class="text-sunshine">RUC-20607366382. © 2021 N&G LOGISTIC E.I.R.L. SERVICE</span></div>
  </footer>
</div>