<div class="container-fluid my-3">
  <div *ngIf="showDivAlert" class="row">
    <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
        <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
        </symbol>
    </svg>
  
    <div class="alert alert-success d-flex align-items-center shadow" role="alert">
        <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-circle-fill"/></svg>
        <div>
            Se registro su pedido, en breve nos comunicaremos con usted.
        </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <h2 class="text-center">LISTA DE PRODUCTOS</h2>
      <div class="mb-3">
        <label for="buscador" class="form-label">Buscador de productos:</label>
        <div class="input-group">
          <input [formControl]="buscador" type="text" class="form-control" id="buscador" placeholder="Ingrese producto" aria-label="Recipient's username with two button addons">
          <button [hidden]="buscador.value === ''" (click)="searchProducto('clean')" class="btn btn-outline-secondary" type="button">x</button>
          <button [hidden]="buscador.value === ''" (click)="searchProducto('buscar')" (keyup.enter)="searchProducto('buscar')" class="btn btn-outline-secondary" type="button">Buscar</button>
        </div>
      </div>
      <app-target1 [productos]="array_productos"></app-target1>
    </div>
    <div class="col-md-6 align-self-end">
      <div class="row mb-1">
        <div class="col-md-6">
          <h4>Pedido N°:</h4>
          <h3 class="fw-bold text-success">{{labelNumPedido}}</h3>
        </div>
        <div class="col-md-6">
          <h4>Total:</h4>
          <h3 class="fw-bold text-success text-end"><span>S./</span> {{labelTotal}}</h3>
        </div>
      </div>
      <app-target2 [itemsPedido]="itemsPedido"></app-target2>
      <div class="row">
        <div class="col-12 text-start">
          <div class="mb-3">
            <label for="buscador" class="form-label">Observación:</label>
            <div class="input-group">
              <input [formControl]="observation" type="text" class="form-control" id="observation">
            </div>
          </div>
        </div>
        <div class="col-12 text-center">
          <button [hidden]="itemsPedido.length === 0" (click)="reservarPedido()" class="btn btn-secondary w-75">Reservar pedido</button>
        </div>
      </div>
    </div>
  </div>
</div>

<a (click)="signOut()" class="btn-flotante">
  Cerrar sesión
</a>

<style>
   .btn-flotante {
    cursor: pointer;
    text-decoration: none;
    font-size: 14px; /* Cambiar el tamaño de la tipografia */
    text-transform: uppercase; /* Texto en mayusculas */
    font-weight: bold; /* Fuente en negrita o bold */
    color: #ffffff; /* Color del texto */
    border-radius: 5px; /* Borde del boton */
    letter-spacing: 2px; /* Espacio entre letras */
    background-color: #E91E63; /* Color de fondo */
    padding: 5px; /* Relleno del boton */
    position: fixed;
    top: 40px;
    right: 40px;
    transition: all 300ms ease 0ms;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    z-index: 9999;
  }
</style>