<div class="container-fluid my-3">
    <div class="row">
        <div class="col-md-4">
            <h2>Lista de pedidos:</h2>
            <div class="mb-3">
                <label for="buscador" class="form-label">Buscador de pedido:</label>
                <div class="input-group">
                    <input [formControl]="buscador" type="text" class="form-control" id="buscador" placeholder="Ingrese pedido" aria-label="Recipient's username with two button addons">
                    <button [hidden]="buscador.value === ''" (click)="searchPedido('clean')" class="btn btn-outline-secondary" type="button">x</button>
                    <button [hidden]="buscador.value === ''" (click)="searchPedido('buscar')" (keyup.enter)="searchPedido('buscar')" class="btn btn-outline-secondary" type="button">Buscar</button>
                </div>
            </div>
            <div class="overflow-auto" style="height: 400px;">
                <app-item5 [pedido]="pedido" class="row mb-3 m-0 align-items-center" *ngFor="let pedido of pedidos"></app-item5>
            </div>
        </div>
        <div class="col-md-8">
            <div class="row">
                <div class="col-md-8">
                    <h4>Razon social:</h4>
                    <h5 class="fw-bold text-success">{{pedidoSelect.nombre}}</h5>
                </div>
                <div class="col-md-4">
                    <h4>RUC:</h4>
                    <h5 class="fw-bold text-success">{{pedidoSelect.ruc}}</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <h4>Fecha:</h4>
                    <h5 class="fw-bold text-success">{{pedidoSelect.fecha}}</h5>
                </div>
                <div class="col-md-2">
                    <h4>N° Pedido:</h4>
                    <h5 class="fw-bold text-success">{{pedidoSelect.numpedido}}</h5>
                </div>
                <div class="col-md-5">
                    <!-- <h4>Correo:</h4>
                    <h5 class="fw-bold text-success">{{pedidoSelect.correo}}</h5> -->
                </div>
                <div class="col-md-3">
                    <h4>Total:</h4>
                    <h4 class="fw-bold text-success text-end"><span>S./</span>{{pedidoSelect.monto}}</h4>
                </div>
            </div>
            <div class="row bg-secondary py-1">
                <div class="col-md-3 fw-bold">
                    Nombre:
                </div>
                <div class="col-md-3 fw-bold">
                    Laboratorio:
                </div>
                <div class="col-md-2 fw-bold">
                    Precio:
                </div>
                <div class="col-md-2 fw-bold">
                    Cantidad:
                </div>
                <div class="col-md-2 fw-bold">
                    Subtotal:
                </div>
            </div>
            <div class="overflow-auto" style="height: 400px;">
                <app-item6 [itemList]="itemList" class="row mb-3 m-0" *ngFor="let itemList of pedidoSelect.lista"></app-item6>
            </div>
            <div class="row">
                <div class="col-12 text-start">
                    <div class="mb-3">
                      <h4>Observación:</h4>
                      <p>{{pedidoSelect.observacion}}</p>
                    </div>
                </div>
                <div class="col-6 text-center">
                  <button [hidden]="pedidoSelect.estado.length === 0" (click)="btnAtendido()" class="btn btn-secondary w-75">{{pedidoSelect.estado === 'atendido' ? 'pendiente' : 'atendido'}}</button>
                </div>
                <div class="col-6 text-center">
                    <button [hidden]="pedidoSelect.estado.length === 0" (click)="btnDwPDF()" class="btn btn-secondary w-75">Descargar PDF</button>
                  </div>
            </div>
        </div>
    </div>
</div>