<div class="col-md-3">
    <span>{{producto.producto}}</span>
</div>
<div class="col-md-3 text-center">
    <span>{{producto.laboratorio}}</span>
</div>
<div class="col-md-1 text-center">
    <span>{{producto.precio}}</span>
</div>
<div class="col-md-2">
    <input 
        [formControl]="cantidad"
        type="number"
        id="cantidad"
        placeholder="00"
        class="form-control"
        min="0">
</div>
<div class="col-md-1 text-center">
    <span>{{subtotal}}</span>
</div>
<div class="col-md-2">
    <button [disabled]="!(cantidad.value>0)" class="btn btn-secondary" (click)="addItemListPedido()">Agregar</button>
</div>
