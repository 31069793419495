import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PedidosComponent } from './page/pedidos/pedidos.component';
import { AdministracionComponent } from './page/administracion/administracion.component';
import { SesionComponent } from './page/sesion/sesion.component';
import { Target1Component } from './components/target1/target1.component';
import { ReactiveFormsModule } from '@angular/forms';
import { Item1Component } from './components/item1/item1.component';
import { Item2Component } from './components/item2/item2.component';
import { Target2Component } from './components/target2/target2.component';
import { MyGuardGuard } from './services/my-guard.guard';
import { IntercomService } from './services/intercom.service';
import { AutCredencialesComponent } from './page/administracion/aut-credenciales/aut-credenciales.component';
import { ListProductosComponent } from './page/administracion/list-productos/list-productos.component';
import { ListPedidosComponent } from './page/administracion/list-pedidos/list-pedidos.component';
import { Item3Component } from './components/item3/item3.component';
import { Item4Component } from './components/item4/item4.component';
import { ComBackendService } from './services/com-backend.service';
import { FuncionesService } from './services/funciones.service';
import { Item5Component } from './components/item5/item5.component';
import { Item6Component } from './components/item6/item6.component';

@NgModule({
  declarations: [
    AppComponent,
    PedidosComponent,
    AdministracionComponent,
    SesionComponent,
    Target1Component,
    Item1Component,
    Item2Component,
    Target2Component,
    AutCredencialesComponent,
    ListProductosComponent,
    ListPedidosComponent,
    Item3Component,
    Item4Component,
    Item5Component,
    Item6Component,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule
  ],
  providers: [
    MyGuardGuard,
    IntercomService,
    ComBackendService,
    FuncionesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
