import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Producto } from 'src/app/model/producto';
import { IntercomService } from 'src/app/services/intercom.service';

@Component({
  selector: 'app-item1',
  templateUrl: './item1.component.html',
  styleUrls: ['./item1.component.css']
})
export class Item1Component implements OnInit {

  @Input() producto:Producto;
  cantidad = new FormControl('0');

  constructor(private intercom:IntercomService) { }

  ngOnInit(): void {
  }

  addItemListPedido(){
    //console.log(this.producto.codigo); Log de ayuda
    this.intercom.announceitemPedido({
      producto: this.producto,
      cantidad: this.cantidad.value,
      subtotal: this.subtotal
    });
    this.cantidad.reset(0);
  }
  
  public get subtotal() : string {    
    let cant = Number.parseInt(this.cantidad.value);
    let prec = Number.parseFloat(this.producto.precio);
    let desc = Number.parseFloat((typeof this.producto.descuento === 'undefined' || this.producto.descuento === '' ? '0' : this.producto.descuento))*prec/100;
    prec = (cant > 11 ? prec-desc : prec);
    return (cant*prec).toFixed(2).toString();
  }
  

}
