<div class="col-md-3">
    <ul>
        <li>{{credencial.nombre}}</li>
        <li>{{credencial.ruc}}</li>
        <li>{{credencial.correo}}</li>
    </ul>
</div>
<div class="col-md-3">
    {{credencial.telefono}}
</div>
<div class="col-md-2">
    {{credencial.clave}}
</div>
<div [class]="classEstado">
    {{credencial.estado}}
</div>
<div class="col-md-2">
    <button class="btn btn-secondary" (click)="sendMail()">Enviar</button>
    <button class="btn btn-secondary ms-2" (click)="deleteUser()">Borrar</button>
</div>
