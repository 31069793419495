import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FuncionesService {

  getFormDataUp(data:{url:string,params:string,data:string}):FormData{
    let formData = new FormData();
    formData.append('url', data.url);
    formData.append('params', data.params);
    formData.append('data', data.data);
    return formData;
  }

}
