import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ComBackendService } from 'src/app/services/com-backend.service';
import { FuncionesService } from 'src/app/services/funciones.service';
import { IntercomService } from 'src/app/services/intercom.service';

@Component({
  selector: 'app-item4',
  templateUrl: './item4.component.html',
  styleUrls: ['./item4.component.css']
})
export class Item4Component implements OnInit {

  @Input() producto:{visible:boolean, codigo:string, producto:string, laboratorio:string, precio:string, descuento?:string};
  formData:FormData;
  productoForm:FormGroup = new FormGroup({
    visible: new FormControl(true),
    codigo: new FormControl(''),
    producto: new FormControl(''),
    laboratorio: new FormControl(''),
    precio: new FormControl(''),
    descuento: new FormControl(''),
  });

  constructor(private interCom:IntercomService, private comBackend:ComBackendService, private funciones:FuncionesService) { }

  ngOnInit(): void {
    this.productoForm.setValue(this.producto);
  }

  async saveChange(criterio:string){

    if (criterio === "update") {
      console.log("Update producto", this.productoForm.value);
      this.interCom.showModal(true,{message:'cargando...', title:'Aviso'});
      this.formData = this.funciones.getFormDataUp({
        url:'productos',
        params:'PUT',
        data:JSON.stringify(this.productoForm.value)});
      let response = await this.comBackend.getRequestBackend(this.formData);
      console.log(response); //Log de Ayuda
      setTimeout(() => {
        this.interCom.showModal(true);
      }, 1500);
    } else if (criterio === "delete") {
      console.log("Delete producto", this.productoForm.value);
      this.interCom.showModal(true,{message:'cargando...', title:'Aviso'});
      this.formData = this.funciones.getFormDataUp({
        url:'productos',
        params:'DELETE',
        data:JSON.stringify({ clausula_where: `codigo='${this.productoForm.get('codigo').value}'` })});
      let response = await this.comBackend.getRequestBackend(this.formData);
      console.log(response); //Log de Ayuda
      setTimeout(() => {
        this.interCom.showModal(true);
      }, 1500);
    }
  }

}
