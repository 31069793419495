import { APP_INITIALIZER, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ComBackendService } from 'src/app/services/com-backend.service';
import { FuncionesService } from 'src/app/services/funciones.service';
import { IntercomService } from 'src/app/services/intercom.service';

@Component({
  selector: 'app-list-productos',
  templateUrl: './list-productos.component.html',
  styleUrls: ['./list-productos.component.css']
})
export class ListProductosComponent implements OnInit, OnDestroy {

  buscador:FormControl = new FormControl('');
  fileProductos:any;
  disabledBtnAdd:boolean = true;
  formData:FormData;
  productos:Array<{visible:boolean, codigo:string, producto:string, laboratorio:string, precio:string, descuento?:string}> = [];
  
  constructor(private comBackend:ComBackendService, private interCom:IntercomService, private funciones:FuncionesService) { }

  ngOnInit(): void {
    this.initializer();
  }
  ngOnDestroy(): void {
    localStorage.removeItem('list-productos');
  }

  async initializer(){
    this.productos = [];
    this.interCom.showModal(true,{message:'cargando...', title:'Aviso'});
    this.formData = this.funciones.getFormDataUp({
      url:'productos',
      params: 'GET',
      data: JSON.stringify({ clausula_where: 'none' })
    });
    let response = await this.comBackend.getRequestBackend(this.formData);
    console.log(response); //Log de Ayuda
    response.response.forEach((item,index) => {
      item.visible === "1" ? response.response[index].visible = true : response.response[index].visible = false;
    });
    this.productos = response.response;
    localStorage.setItem('list-productos', JSON.stringify(this.productos));
    setTimeout(() => {
      this.interCom.showModal(true);
    }, 1500);
  }

  searchProducto(criterio:string){

    if (criterio === "clean" && localStorage.getItem('list-productos').length > 0){
      this.buscador.reset('');
      this.productos = JSON.parse(localStorage.getItem('list-productos'));
    } else if (criterio === "buscar" && localStorage.getItem('list-productos').length > 0) {
      this.productos = [];
      JSON.parse(localStorage.getItem('list-productos')).forEach(producto =>{
        if (producto.codigo.toLocaleLowerCase().includes(this.buscador.value.toLocaleLowerCase()) || producto.producto.toLocaleLowerCase().includes(this.buscador.value.toLocaleLowerCase())) {
          this.productos.push(producto);
        }
      })
    }
  }

  onFileChange(event){
    if (event.target.files.length > 0) {
      this.fileProductos = event.target.files[0];
      this.disabledBtnAdd = false;
    } else {
      this.disabledBtnAdd = true;
    }
  }
  async addProducto(){

    this.interCom.showModal(true,{message:'cargando...', title:'Aviso'});
    try {
      this.formData = new FormData();
      this.formData.append('url', 'productos');
      this.formData.append('params', 'POST');
      this.formData.append('data', 'none');
      this.formData.append('fileProductos', this.fileProductos);
      let response = await this.comBackend.getRequestBackend(this.formData);
      console.log(response); //Log de Ayuda
    } catch (error) {
      console.log(error); //Log de ayuda
      window.alert("intentelo nuevamente, ocurrio un error inesperado.");
    }
    this.interCom.showModal(true);
    setTimeout(() => {
      this.initializer();
    }, 1000);
  }

}
