import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyGuardGuard implements CanActivate {

  constructor(private router: Router){ }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      console.log("AlwaysAuthGuard");
      if (localStorage.getItem("permission") === "true"){
        //localStorage.removeItem("permission");
        return true;
      } else {
        window.alert("Session vencida, favor autenticarse nuevamente");
        this.router.navigate(['/']);
        return false;
      }
  }
  
}
