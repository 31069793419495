<div class="h-100 d-flex align-items-center">
    <div class="container d-flex flex-column">
        <h2 class="my-3 text-center">Lista de productos</h2>
        <hr>
        <div class="input-group mb-3">
            <label class="input-group-text" for="inputGroupFile01">Subir excel</label>
            <input type="file" accept=".csv" class="form-control" id="inputGroupFile01" (change)="onFileChange($event)">
        </div>
        <div class="d-grid gap-2 mb-3">
            <button [disabled]="disabledBtnAdd" class="btn btn-primary btn-block" (click)="addProducto()">Agregar productos</button>
        </div>
        <hr>
        <div class="mb-3">
            <label for="buscador" class="form-label">Buscador de producto:</label>
            <div class="input-group">
                <input [formControl]="buscador" type="text" class="form-control" id="buscador" placeholder="Ingrese NOMBRE del producto o CODIGO" aria-label="Recipient's username with two button addons">
                <button [hidden]="buscador.value === ''" (click)="searchProducto('clean')" class="btn btn-outline-secondary" type="button">x</button>
                <button [hidden]="buscador.value === ''" (click)="searchProducto('buscar')" (keyup.enter)="searchProducto('buscar')" class="btn btn-outline-secondary" type="button">Buscar</button>
            </div>
        </div>
        <div class="row bg-secondary py-1">
            <div class="col-md-1 fw-bold">
                Visible:
            </div>
            <div class="col-md-3 fw-bold">
                Nombre:
            </div>
            <div class="col-md-3 fw-bold">
                Laboratorio:
            </div>
            <div class="col-md-2 fw-bold">
                Precio de venta:
            </div>
            <div class="col-md-1 fw-bold">
                Descuento:
            </div>
            <div class="col-md-1 fw-bold">
                Acción:
            </div>
        </div>
        <app-item4 class="row shadow mb-3" *ngFor="let producto of productos" [producto]="producto"></app-item4>
    </div>
</div>