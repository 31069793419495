<div class="col-md-3">
    <span>{{item.producto.producto}}</span>
</div>
<div class="col-md-3">
    <span>{{item.producto.laboratorio}}</span>
</div>
<div class="col-md-1">
    <span>{{item.producto.precio}}</span>
</div>
<div class="col-md-2">
    <span>{{item.cantidad}}</span>
</div>
<div class="col-md-1">
    <span>{{item.subtotal}}</span>
</div>
<div class="col-md-2">
    <button class="btn" (click)="deleteItemFromList()">Eliminar</button>
</div>