<div class="row h-100 m-0">
  <div class="col-md-6 offset-md-3 d-flex align-items-center">
    <div class="card w-100 shadow rounded">
      <div class="card-body">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">Usuario</label>
          <input #email type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com">
        </div>
        <div class="mb-3">
          <label for="exampleFormControlInput2" class="form-label">Contraseña</label>
          <input #password type="password" class="form-control" id="exampleFormControlInput2" placeholder="******">
        </div>
        <div class="d-flex justify-content-center">
          <button class="btn btn-primary" (click)="validar(email, password)">Entrar</button>
        </div>
      </div>
    </div>
  </div> 
</div>
