<div class="row bg-secondary">
    <div class="col-md-3">
        <span>Producto</span>
    </div>
    <div class="col-md-3">
        <span>Laboratorio</span>
    </div>
    <div class="col-md-1">
        <span>Precio</span>
    </div>
    <div class="col-md-2">
        <span>Cant.</span>
    </div>
    <div class="col-md-1">
        <span>Sub.</span>
    </div>
    <div class="col-md-2">
        <span>Acción</span>
    </div>
</div>
<div class="overflow-auto" style="height: 400px;">
    <app-item1 *ngFor="let producto of productos" class="row mb-3 m-0 align-items-center formItem1" [producto]="producto"></app-item1>
</div>