import { Component, Input, OnInit } from '@angular/core';
import { IntercomService } from 'src/app/services/intercom.service';

@Component({
  selector: 'app-item2',
  templateUrl: './item2.component.html',
  styleUrls: ['./item2.component.css']
})
export class Item2Component implements OnInit {

  @Input() item:any;

  constructor(private interCom: IntercomService) { }

  ngOnInit(): void {
  }

  deleteItemFromList(){
    this.interCom.announceCodigo(this.item.producto.codigo);
  }

}
