import { Component, Input, OnInit } from '@angular/core';
import { ComBackendService } from 'src/app/services/com-backend.service';
import { FuncionesService } from 'src/app/services/funciones.service';
import { IntercomService } from 'src/app/services/intercom.service';

@Component({
  selector: 'app-item3',
  templateUrl: './item3.component.html',
  styleUrls: ['./item3.component.css']
})
export class Item3Component implements OnInit {

  @Input() credencial:{correo:string, nombre:string, ruc:string, telefono:string, clave:string, estado:string};
  formData:FormData;
  valorClassEstado:{accept:string, denied:string} = {
    accept: "col-md-2 rounded-pill text-center bg-success",
    denied: "col-md-2 rounded-pill text-center bg-danger"
  };
  classEstado:string = this.valorClassEstado.denied;

  constructor(private interCom:IntercomService, private comBackend:ComBackendService, private funciones:FuncionesService) { }

  ngOnInit(): void {
    this.credencial.estado === 'autorizado' ? this.classEstado = this.valorClassEstado.accept : this.classEstado = this.valorClassEstado.denied;
  }

  async sendMail(){

    this.interCom.showModal(true, {message:'cargando...', title:'Aviso'});
    this.formData = this.funciones.getFormDataUp({
      url:'credenciales',
      params: 'PUT',
      data: JSON.stringify({
        nombre:this.credencial.nombre,
        ruc:this.credencial.ruc,
        correo:this.credencial.correo,
        telefono:this.credencial.telefono,
        clave:this.credencial.clave,
        estado:"autorizado"})
    });
    let response = await this.comBackend.getRequestBackend(this.formData);
    console.log(response); //Log de Ayuda
    if (response.response) {
      this.credencial.estado = "autorizado";
      this.classEstado = this.valorClassEstado.accept;
    } else {
      console.log('NO se envio el correo');
    }
    setTimeout(() => {
      this.interCom.showModal(true);
    }, 1500);
  }

  async deleteUser(){

    this.interCom.showModal(true,{message:'cargando...', title:'Aviso'});
    this.formData = this.funciones.getFormDataUp({
      url:'credenciales',
      params: 'DELETE',
      data: JSON.stringify({ clausula_where: `correo='${this.credencial.correo.toString()}'` })
    });
    let response = await this.comBackend.getRequestBackend(this.formData);
    console.log(response); //Log de Ayuda
    (response.response) ? this.interCom.announceCorreo(this.credencial.correo) : '';
    setTimeout(() => {
      this.interCom.showModal(true);
    }, 1500);
  }

}
