<div class="col-md-3">
    {{itemList.producto.producto}}
</div>
<div class="col-md-3">
    {{itemList.producto.laboratorio}}
</div>
<div class="col-md-2">
    {{itemList.producto.precio}}
</div>
<div class="col-md-2">
    {{itemList.cantidad}}
</div>
<div class="col-md-2">
    {{itemList.subtotal}}
</div>
